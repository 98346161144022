<template>
  <b-container fluid>
    <b-row no-gutters style="min-height: 80vh">
      <b-col class="text-center my-auto">
        <b-jumbotron class="text-center pl-0 pr-0">
          <template v-slot:header
            ><h1 class="display-4 font-weight-bold mr-1">
              Invalid Credentials
            </h1></template
          >
          <template v-slot:lead>
            <p class="lead">
              Oops, we can't seem to find the order you are looking for. Please
              clear your history, close the browser, and try again.
            </p>
          </template>
          <router-link
            class="btn font-weight-bold justify-content-center w-auto"
            to="/"
            >Go to Home</router-link
          >
        </b-jumbotron>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "InvalidCredentials",
};
</script>

<style scoped>
.jumbotron {
  background-color: white;
}
.display-4 {
  font-weight: bolder;
}
/* Mobile Styling */
@media only screen and (max-width: 600px) {
  .display-4 {
    font-size: 2.75rem;
  }
}
</style>
